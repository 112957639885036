@tailwind components;
@tailwind base;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto:wght@700&display=swap');
:root {
  --teal-primary-color: #006666;
  --teal-secondary-color: #009999;
  --teal-darker: #066060;
  --white-color: #ffffff;
  --black-color: #2a2a2a;
  --gray-color: #A3B2BF;
  --red-color: #fe0000;
}

body {
  margin: 0;
  // background: linear-gradient(179deg, #e5e9ec 9.78%, #c2c5cb 99.16%);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black-color);
}

* {
  ::-webkit-scrollbar {
    width: 8px;
    margin-right: 15px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffff;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--teal-secondary-color);
    border-radius: 8px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--teal-darker);
  }
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  //  overflow: hidden;
}

.mainContainer {
  width: 100%;
  min-height: 100vh;
  padding: 3rem 3rem;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-baseline {
  align-items: baseline;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-vh-full {
  min-height: 100vh;
}

.w-vw-full {
  width: 100vw;
}

.divider {
  background: var(--gray-color);
  width: 45%;
  height: 1px;
}

.m-3 {
  margin: 3rem;
}
.hidden {
  display: none;
}
.image-clip {
  clip-path: circle(50% at 50% 50%);
}
.bg-gray {
  background: #f9fafb;
}

li {
  list-style: none;
}
